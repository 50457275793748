import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'

import {
  HistoryOptions,
  Nullable,
  Serializers,
  TransitionOptions,
} from '../utils/urlState'

type KeyMapValue<Type> = Serializers<Type> & {
  defaultValue?: Type
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseURLStatesKeysMap<Map = any> = {
  [Key in keyof Map]: KeyMapValue<Map[Key]>
}

type Values<T extends UseURLStatesKeysMap> = {
  [K in keyof T]: T[K]['defaultValue'] extends NonNullable<
    ReturnType<T[K]['parse']>
  >
    ? NonNullable<ReturnType<T[K]['parse']>>
    : ReturnType<T[K]['parse']> | null
}

type UpdaterFn<T extends UseURLStatesKeysMap> = (
  old: Values<T>
) => Partial<Nullable<Values<T>>>

type SetValues<T extends UseURLStatesKeysMap> = (
  values: Partial<Nullable<Values<T>>> | UpdaterFn<T>,
  method?: HistoryOptions,
  transitionOptions?: TransitionOptions
) => Promise<boolean>

type SerializeValues<T extends UseURLStatesKeysMap> = (
  values: Partial<Nullable<Values<T>>> | UpdaterFn<T>
) => string

type UseURLStatesReturn<T extends UseURLStatesKeysMap> = [
  Values<T>,
  SetValues<T>,
  SerializeValues<T>,
  () => void,
]

/**
 * Synchronise multiple query string arguments to React state in Next.js
 *
 * @param keys - An object describing the keys to synchronise and how to
 *               serialise and parse them.
 *               Use `queryTypes.(string|integer|float)` for quick shorthands.
 */
export function useURLState<KeyMap extends UseURLStatesKeysMap>(
  keys: KeyMap
): UseURLStatesReturn<KeyMap> {
  const router = useRouter()

  type V = Values<KeyMap>

  const getValues = useCallback((): V => {
    if (typeof window === 'undefined') {
      return Object.keys(keys).reduce((obj, key) => {
        const { defaultValue } = keys[key as keyof KeyMap]
        return {
          ...obj,
          [key]: defaultValue ?? null,
        }
      }, {} as V)
    }
    const query = new URLSearchParams(window.location.search)
    return Object.keys(keys).reduce((values, key) => {
      const { parse, defaultValue } = keys[key as keyof KeyMap]
      const value = query.get(key)
      const parsed =
        value !== null
          ? (parse(value) ?? defaultValue ?? null)
          : (defaultValue ?? null)
      return {
        ...values,
        [key]: parsed,
      }
    }, {} as V)
  }, [keys])

  const values = useMemo(
    getValues,
    Object.keys(keys).map(key => router.query[key])
  )

  const update = useCallback<SetValues<KeyMap>>(
    (stateUpdater, method = 'replace', transitionOptions) => {
      const search = serialize(stateUpdater)

      const hash = window.location.hash
      if (method === 'replace') {
        return router.replace(
          {
            pathname: window.location.pathname,
            hash,
            search,
          },
          null,
          transitionOptions
        )
      } else {
        return router.push(
          {
            pathname: window.location.pathname,
            hash,
            search,
          },
          null,
          transitionOptions
        )
      }
    },
    [keys]
  )

  const reset = useCallback(() => {
    const obj = Object.keys(keys).reduce((values, key) => {
      const { defaultValue } = keys[key as keyof KeyMap]
      return {
        ...values,
        [key]: defaultValue,
      }
    }, {})

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    update(obj, 'replace', { shallow: true })
  }, [keys])

  const serialize = useCallback<SerializeValues<KeyMap>>(
    stateUpdater => {
      const oldValues = getValues()
      const newValues =
        typeof stateUpdater === 'function'
          ? stateUpdater(oldValues)
          : stateUpdater

      const query = new URLSearchParams(window.location.search)

      Object.keys(newValues).forEach(key => {
        const newValue = newValues[key]

        if (
          newValue === undefined ||
          newValue === null ||
          newValue === '' ||
          (Array.isArray(newValue) && isEmpty(newValue))
        ) {
          query.delete(key)
        } else {
          const { serialize = String } = keys[key]
          query.set(key, serialize(newValue))
        }
      })
      const search = query.toString()

      return search
    },
    [keys]
  )

  return [values, update, serialize, reset]
}
