import { FC, ReactNode, useRef } from 'react'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import * as styles from './styles'

type Breakpoint = 'md' | 'bmd' | 'mlg' | 'lg'
export type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

export interface BookingBarPanelProps {
  id?: string
  selected?: boolean
  tabId?: string
  children?: ReactNode
  index?: number
  span?: BreakpointMap<number>
  columnCount?: number
  onClickOutside?: () => void
  style?: unknown
}

export const BookingBarPanel: FC<BookingBarPanelProps> = ({
  id,
  selected,
  tabId,
  children,
  index,
  span,
  columnCount,
  onClickOutside,
  style,
}) => {
  const panelRef = useRef(null)

  useOnClickOutside(panelRef, () => {
    if (!selected) return

    onClickOutside?.()
  })

  return (
    <styles.Panel
      ref={panelRef}
      role="tabpanel"
      id={id}
      hidden={!selected}
      tabIndex={0}
      aria-labelledby={tabId}
      index={index}
      span={span}
      columnCount={columnCount}
      style={style}
    >
      {children}
    </styles.Panel>
  )
}
