import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { ProgressButton } from '../../elements/ProgressButton/ProgressButton'
import { Type } from '../../elements/Typography/Typography'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'
import { PanelPosition } from './BookingBar'
import { BreakpointMap } from './BookingBarPanel'

interface ItemProps {
  selected: boolean
  hasPanel: boolean
  themeColor?: string
}

interface GridProps {
  columnCount: number
  columnTemplate: string
}

interface PanelProps {
  index: number
  span: BreakpointMap<number>
  columnCount: number
}

export const MobileBar = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
`

export const BookingBarButton = styled(ProgressButton)({
  flex: '1 0 50%',

  p: {
    fontSize: 16,
  },
})

export const FixedMobileBar = styled(motion.div)<{
  visible: boolean
  $themeStyle?: PartialBookingEngine['spaBookingBar']
}>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 90px;
  z-index: ${zIndex.modal + 2};
  padding: ${({ theme }) => `0 ${theme.spacing[2.5]}`};
  background-color: ${colors.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  background-color: ${({ $themeStyle }) =>
    $themeStyle?.fixedMobileBarBackground};
`

export const Bar = styled.div`
  display: none;
  position: relative;
  z-index: ${zIndex.behindMenu};

  .t-dark & {
    color: ${colors.dark};
  }

  ${media.md(css`
    display: block;
  `)}

  ${media.mlg(css`
    margin-bottom: 0;
  `)}
`

export const List = styled.ul<GridProps>`
  position: relative;
  overflow: visible;
  display: grid;
  gap: 14px 0;
  background-color: transparent;
  z-index: 3;
  height: 67px;

  ${props =>
    media.md(css`
      height: 86px;
      display: grid;
      grid-template-columns: ${props.columnTemplate ||
      `repeat(${props.columnCount}, minmax(150px, 1fr)) 150px;`};
      grid-gap: 0px;
      background-color: ${colors.white};
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    `)}
  ${props =>
    media.lg(css`
      grid-template-columns: ${props.columnTemplate ||
      `repeat(${props.columnCount}, minmax(150px, 1fr)) 227px;`};
    `)}
`

export const Item = styled.li<ItemProps>`
  position: relative;
  grid-column: span 1;
  display: flex;
  align-items: center;
  height: 67px;

  ${({ hasPanel, themeColor }) =>
    media.md(css`
      height: 86px;
      margin: auto 0;
      border-left: 1px solid ${colors.lightGrey};
      box-shadow: none;

      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        border-bottom: solid 4px ${themeColor ?? colors.deepBlue};
        opacity: 0;
      }

      &:hover {
        ::after {
          opacity: ${hasPanel ? 1 : 0};
        }
      }
    `)}

  ${props =>
    props.selected &&
    css`
      ::after {
        opacity: 1;
      }
    `}
`
export const Value = styled(Type)`
  margin: 0;
`

export const ItemWrapper = styled.button`
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: pointer;
  padding: ${({ theme }) => `0 ${theme.spacing[2]}`};

  &:disabled {
    color: ${colors.black};
    cursor: default;
  }
`

export const PortalGrid = styled.div<GridProps>`
  ${props =>
    media.md(css`
      display: grid;
      grid-template-columns: ${props.columnTemplate ||
      `repeat(${props.columnCount}, minmax(150px, 1fr)) 150px;`};
      grid-gap: 0px;
    `)}

  ${props =>
    media.lg(css`
      grid-template-columns: ${props.columnTemplate ||
      `repeat(${props.columnCount}, minmax(150px, 1fr)) 227px;`};
    `)}
`

function calculateStartIndex(
  defaultIndex: number,
  span: number,
  columnCount: number
) {
  if (defaultIndex - 1 + span > columnCount) {
    return 1
  }
  return defaultIndex
}

export const Panel = styled.div<PanelProps>`
  background: ${colors.white};

  ${media.md(css`
    padding: 24px;
  `)};

  ${({ index, span, columnCount }) => {
    const mdSpan = span.md ?? 1
    const start = calculateStartIndex(index, mdSpan, columnCount)

    return media.md(css`
      grid-column: ${`${start} / span ${mdSpan}`};
    `)
  }}
  ${({ index, span, columnCount }) => {
    const mdSpan = span.bmd ?? span.md ?? 1
    const start = calculateStartIndex(index, mdSpan, columnCount)

    return media.md(css`
      grid-column: ${`${start} / span ${mdSpan}`};
    `)
  }}
  ${({ index, span, columnCount }) => {
    const bmdSpan = span.mlg ?? span.bmd ?? span.md ?? 1
    const start = calculateStartIndex(index, bmdSpan, columnCount)

    return media.bmd(css`
      grid-column: ${`${start} / span ${bmdSpan}`};
    `)
  }}
    ${({ index, span, columnCount }) => {
    const lgSpan = span.lg ?? span.mlg ?? span.bmd ?? span.md ?? 1
    const start = calculateStartIndex(index, lgSpan, columnCount)

    return media.lg(css`
      grid-column: ${`${start} / span ${lgSpan}`};
    `)
  }};
`

export const Portal = styled.div<{ panelPosition: PanelPosition }>`
  display: none;

  ${({ panelPosition }) =>
    media.md(css`
      display: block;
      position: absolute;
      z-index: ${zIndex.above2};
      left: 0;
      right: 0;

      ${panelPosition === PanelPosition.Above ? 'bottom: 100%;' : 'top: 100%;'}
      ${Panel} {
        box-shadow: ${panelPosition === PanelPosition.Above
          ? '0px -8px 30px rgba(0, 0, 0, 0.1);'
          : '0px 8px 30px rgba(0, 0, 0, 0.1);'};
      }
    `)}
`
